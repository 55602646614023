import {
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  West,
  East,
} from '@mui/icons-material';

function extractValues(filters) {
  return Object.values(filters).flat();
}

export const DIRECTION_ICONS = {
  N: <North />,
  E: <East />,
  S: <South />,
  W: <West />,
  NE: <NorthEast />,
  NW: <NorthWest />,
  SE: <SouthEast />,
  SW: <SouthWest />,
};

const KEYS_MAP = {
  CNG: 'StationFuelTypeCNG',
  LNG: 'StationFuelTypeLNG',
  'Renewable (RNG)': 'StationFuelTypeRDM',
  'Vans and box trucks': 'VehicleTypesBoxTrucks',
  'Refuse and buses': 'VehicleTypesCarsAndVans',
  'Tractor-trailers': 'VehicleTypesSemiTrucks',
  Operational: 'StationStatus',
  'Under Maintenance': 'StationStatus',
  'Coming Soon': 'StationStatus',
  '24/7': 'HoursOpenIs24H',
  'Open Now': 'HoursOpenIs24H',
  Comdata: 'PaymentTypesAcceptedComData',
  'Fuelman Fleetwide': 'PaymentTypesAcceptedFuelmanFleetwide',
  'Mastercard Fleet': 'PaymentTypesAcceptedMasterCardFleet',
  TCH: 'PaymentTypesAcceptedTCH',
  'Visa Fleet': 'PaymentTypesAcceptedVisaFleet',
  Amex: 'PaymentTypesAcceptedAmex',
  Discover: 'PaymentTypesAcceptedDiscover',
  Tcheck: 'PaymentTypesAcceptedTcheck',
  'Clean Energy Fuel Card': 'PaymentTypesAcceptedCleanEnergyFuelCard',
  EFS: 'PaymentTypesAcceptedEFS',
  'Legacy EFS': 'PaymentTypesAcceptedLegacyEFS',
  'PFGift Card': 'PaymentTypesAcceptedPFGiftCard',
  Voyager: 'PaymentTypesAcceptedVoyager',
  Fleetone: 'PaymentTypesAcceptedWEXFleetone',
  'PFGIFT Card': 'PaymentTypesAcceptedPFGiftCard',
  Mastercard: 'PaymentTypesAcceptedMasterCard',
  Speedway: 'PaymentTypesAcceptedSpeedway',
  Visa: 'PaymentTypesAcceptedVisa',
};

export const filterServerData = (filters, targetArray) => {
  // Return early if no filters are selected
  if (!Object.keys(filters).length) {
    return targetArray;
  }

  const checkSubFilter = (station, filterKey, filterValues) => {
    // OR condition between subfilters in a single category
    return filterValues.some((value) => {
      const mappedKey = KEYS_MAP[value];

      if (!mappedKey) {
        console.warn(`No mapping found for filter value: ${value}`);
        return false;
      }

      // Custom logic for stationStatus filter
      if (filterKey === 'stationStatus') {
        return value === 'Operational'
          ? station[mappedKey] === 'Active' // Map 'Operational' to 'Active'
          : station[mappedKey] === value;
      }

      // Default logic: check if station value matches 'Yes'
      return station[mappedKey] === 'Yes';
    });
  };

  const filterKeys = [
    'fuelType',
    'hours',
    'paymentTypes',
    'stationStatus',
    'vehicleSize',
    'source',
  ];

  return targetArray.filter((station) => {
    // AND condition between categories
    return filterKeys.every((key) => {
      // Skip filter if it's not provided or empty
      if (!filters[key]?.length) {
        return true;
      }

      // Check if the station passes the filter for this category (with OR condition for subfilters)
      return checkSubFilter(station, key, filters[key]);
    });
  });
};

export const fetchLocationName = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=10&addressdetails=1`
    );
    const data = await response.json();
    if (data && data.address) {
      return data.display_name; // Get the readable location name
    }
    return `Un-Named Location with Lat: ${lat}, Lng: ${lng}`;
  } catch (error) {
    console.error('Error fetching location name:', error);
  }
};

export const clearLocalStorage = () => {
  window?.localStorage?.removeItem('SOURCE_CITY');
  window?.localStorage?.removeItem('DESTINATION_CITY');
  window?.localStorage?.removeItem('SOURCE_CITY_META_DATA');
  window?.localStorage?.removeItem('DESTINATION_CITY_META_DATA');
  window?.localStorage?.removeItem('DESTINATIONS_METADATA');
  window?.localStorage?.removeItem('DESTINATIONS');
};

export const capitalizeEachWord = (str) => {
  return str
    ? str
        .split(' ')
        .map((word) =>
          word.includes('-')
            ? word
                .split('-')
                .map(
                  (subWord) =>
                    subWord.charAt(0).toUpperCase() +
                    subWord.slice(1).toLowerCase()
                )
                .join('-')
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
    : '';
};
