import React from 'react';
import './footer.scss';
import { Box, Typography } from '@mui/material';
import footerLogo from '../../assets/img/clean-energy-logo.svg';

const Footer = () => {
  return (
    <>
      <Box className='footer-wrapper'>
        <Box className='footer-content'>
          <Typography className='footer-text text-copywrite'>
            © 2024 Clean Energy Fuels
          </Typography>
          <Box sx={{ letterSpacing: 0, lineHeight: 0 }}>
            <img src={footerLogo} alt='logo' className='footer-logo' />
          </Box>
          <Typography className='footer-text text-hotline-service'>
            Customer service hotline: 866-809-4869
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
