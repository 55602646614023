import React from 'react';
import 'leaflet/dist/leaflet.css';

import { MapContainer } from 'react-leaflet';
import RoutingOnly from '../TripPlanner/onlyRouting';
import 'leaflet-routing-machine';
import { MAP_VIEWS } from '../../store/constants/mapViewConstants';
import GoogleMapsLayer from '../GoogleMapsComponent';

const ZoomedMap = ({ routeDetails, destinations, focusPoint }) => {
  return (
    <MapContainer
      center={focusPoint}
      zoom={100}
      style={{
        height: '280px',
        width: '310px',
        position: 'relative',
      }}
    >
      <GoogleMapsLayer type={MAP_VIEWS.normal} />
      <RoutingOnly
        sourceCity={{
          lat: routeDetails[0]?.startLocation?.lat,
          lng: routeDetails[0]?.startLocation?.lng,
        }}
        destinationCity={{
          lat: routeDetails[0]?.endLocation?.lat,
          lng: routeDetails[0]?.endLocation?.lng,
        }}
        destinations={destinations}
        zoomed={false}
      />
    </MapContainer>
  );
};

export default ZoomedMap;
