import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import Station from '../../assets/img/station.png';
import './stationDetails.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import Modal from '@mui/material/Modal';
import ContactModal from './contactModal';
import { Close, Print, X } from '@mui/icons-material';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { capitalizeEachWord } from '../../lib/helper';

export default function StationDetails({
  station,
  isOpenModal,
  setOpenModal,
  onGetDirectionsClick,
  handleAddToTrip,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContactModal, setOpenContactModal] = React.useState(false);
  const [IsEmail, setIsEmail] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenContactModal = (type) => {
    if (type === 'email') {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
    setOpenContactModal(true);
    handleClose();
  };
  const handleCloseContactModal = () => setOpenContactModal(false);

  const handleCloseModal = () => setOpenModal(false);

  const handleDirectionsClick = () => {
    onGetDirectionsClick(station); // Send the station data)
    handleCloseModal(); // Optionally close the modal
  };

  const handleAddToTripButtonClick = () => {
    handleAddToTrip(station);
    handleCloseModal();
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className='station-details-section'>
        <Box className='station-details-img'>
          <img src={Station} alt={Station} />
        </Box>
        <Box className='station-details-close-icon' onClick={handleCloseModal}>
          <IconButton>
            <Close sx={{ fontSize: '1rem', color: '#fff' }} />
          </IconButton>
        </Box>
        <Box className='station-details'>
          <Typography variant='h2'>
            {capitalizeEachWord(station?.StationName) || 'N/A'}
          </Typography>
          <Typography variant='body2'>
            {capitalizeEachWord(station?.StationAddress)}{' '}
            {capitalizeEachWord(station?.StationCity)},{' '}
            {capitalizeEachWord(station?.StationState)}{' '}
            {capitalizeEachWord(station?.StationZip)}
          </Typography>
          <Typography
            className='hourlyOpenAndAccepts'
            sx={{ marginBottom: '10px' }}
          >
            Hours:
            <span style={{ marginLeft: '5px' }}>
              {capitalizeEachWord(
                station?.HoursOpen?.replace(/none|None|;/gi, ' ')
              ) || 'N/A'}
            </span>
          </Typography>
          <Typography className='hourlyOpenAndAccepts'>
            Accepts:
            <span style={{ marginLeft: '5px' }}>
              {capitalizeEachWord(
                station?.PaymentTypesAccepted?.replace(/;/g, ', ')
              ) || 'N/A'}
            </span>
          </Typography>
          <Box className='station-wrap'>
            <Box className='station-status'>
              <Typography variant='h4'>Station Status</Typography>
              <Typography variant='body2' sx={{ marginBottom: 0 }}>
                {(station?.StationStatus === 'Active' && 'Operational') ||
                  capitalizeEachWord(station?.StationStatus)}
              </Typography>
            </Box>
            <Box className='station-status'>
              <Typography variant='h4'>Vehicle Types Accepted</Typography>
              <Typography variant='body2' sx={{ marginBottom: 0 }}>
                {[
                  station?.VehicleTypesBoxTrucks === 'Yes' &&
                    'Vans And Box Trucks',
                  station?.VehicleTypesCarsAndVans === 'Yes' &&
                    'Refuse And Buses',
                  station?.VehicleTypesSemiTrucks === 'Yes' &&
                    'Tractor-Trailers',
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
            </Box>
            <Box className='station-status'>
              <Typography variant='h4'>Fuel Types Offered</Typography>
              <Typography variant='body2' sx={{ marginBottom: 0 }}>
                {[
                  station?.StationFuelTypeCNG === 'Yes' && 'CNG',
                  station?.StationFuelTypeDSL === 'Yes' && 'DSL',
                  station?.StationFuelTypeLNG === 'Yes' && 'LNG',
                  // station?.StationFuelTypeRDM === 'Yes' && 'RNG',
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
            </Box>

            <Box className='station-status'>
              <Typography variant='h4'>RNG offered</Typography>
              <Typography variant='body2' sx={{ marginBottom: 0 }}>
                {[station?.StationFuelTypeRDM === 'Yes' && 'Yes']
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
            </Box>
          </Box>

          <Box className='btn-station'>
            <Button onClick={handleDirectionsClick}>Get directions</Button>
            <Button onClick={handleAddToTripButtonClick}>Add to trip</Button>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Share
            </Button>
            <Menu
              id='share-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              className='share-menu'
            >
              <MenuItem onClick={handleClose}>
                <FacebookShareButton
                  style={{ display: 'flex', alignItems: 'center' }}
                  url='http://www.cnglngstations.com' // Ensure the URL is publicly accessible.
                  quote={`Check out this station: ${station?.StationName}, located at ${station?.StationAddress}, ${station?.StationCity}, ${station?.StationState}. Open Hours: ${station?.HoursOpen}`}
                  hashtag='#CleanEnergy'
                >
                  <FacebookIcon sx={{ color: '#1877F2' }} />
                  <Typography sx={{ color: '#1877F2', marginBottom: '0' }}>
                    Facebook
                  </Typography>
                </FacebookShareButton>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <TwitterShareButton
                  style={{ display: 'flex', aliItems: 'center' }}
                  url='http://www.cnglngstations.com'
                  title={`Check out this station: ${station?.StationName}, located at ${station?.StationAddress}, ${station?.StationCity}, ${station?.StationState}. Open Hours: ${station?.HoursOpen}`}
                  hashtags={['CleanEnergy', 'StationLocator']}
                  via='YourTwitterHandle'
                >
                  <X sx={{ color: 'black' }} />
                  <Typography sx={{ color: 'black', marginBottom: '0' }}>
                    X (Twitter)
                  </Typography>
                </TwitterShareButton>
              </MenuItem>
              <MenuItem onClick={() => handleOpenContactModal('email')}>
                <EmailIcon sx={{ color: '#585458' }} />
                <Typography sx={{ color: '#585458', marginBottom: '0' }}>
                  E-mail trip
                </Typography>
              </MenuItem>
              <MenuItem>
                <Print sx={{ color: '#585458' }} />
                <Typography sx={{ color: '#585458', marginBottom: '0' }}>
                  Print map
                </Typography>
              </MenuItem>
            </Menu>
            <ContactModal
              openContactModal={openContactModal}
              handleCloseContactModal={handleCloseContactModal}
              station={station}
              IsEmail={IsEmail}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
